export function getMotion(prefixCls, transitionName) {
    return {
        motionName: transitionName !== null && transitionName !== void 0 ? transitionName : `${prefixCls}-move-up`,
    };
}
/** Wrap message open with promise like function */
export function wrapPromiseFn(openFn) {
    let closeFn;
    const closePromise = new Promise((resolve) => {
        closeFn = openFn(() => {
            resolve(true);
        });
    });
    const result = () => {
        closeFn === null || closeFn === void 0 ? void 0 : closeFn();
    };
    result.then = (filled, rejected) => closePromise.then(filled, rejected);
    result.promise = closePromise;
    return result;
}
