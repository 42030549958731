import { prepareComponentToken, prepareToken } from '.';
import capitalize from '../../_util/capitalize';
import { genSubStyleComponent } from '../../theme/internal';
const genTagStatusStyle = (token, status, cssVariableType) => {
    const capitalizedCssVariableType = capitalize(cssVariableType);
    return {
        [`${token.componentCls}${token.componentCls}-${status}`]: {
            color: token[`color${cssVariableType}`],
            background: token[`color${capitalizedCssVariableType}Bg`],
            borderColor: token[`color${capitalizedCssVariableType}Border`],
            [`&${token.componentCls}-borderless`]: {
                borderColor: 'transparent',
            },
        },
    };
};
// ============================== Export ==============================
export default genSubStyleComponent(['Tag', 'status'], (token) => {
    const tagToken = prepareToken(token);
    return [
        genTagStatusStyle(tagToken, 'success', 'Success'),
        genTagStatusStyle(tagToken, 'processing', 'Info'),
        genTagStatusStyle(tagToken, 'error', 'Error'),
        genTagStatusStyle(tagToken, 'warning', 'Warning'),
    ];
}, prepareComponentToken);
