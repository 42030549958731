import { presetPrimaryColors } from '@ant-design/colors';
export function validProgress(progress) {
    if (!progress || progress < 0) {
        return 0;
    }
    if (progress > 100) {
        return 100;
    }
    return progress;
}
export function getSuccessPercent({ success, successPercent }) {
    let percent = successPercent;
    /** @deprecated Use `percent` instead */
    if (success && 'progress' in success) {
        percent = success.progress;
    }
    if (success && 'percent' in success) {
        percent = success.percent;
    }
    return percent;
}
export const getPercentage = ({ percent, success, successPercent }) => {
    const realSuccessPercent = validProgress(getSuccessPercent({ success, successPercent }));
    return [realSuccessPercent, validProgress(validProgress(percent) - realSuccessPercent)];
};
export const getStrokeColor = ({ success = {}, strokeColor, }) => {
    const { strokeColor: successColor } = success;
    return [successColor || presetPrimaryColors.green, strokeColor || null];
};
export const getSize = (size, type, extra) => {
    var _a, _b, _c, _d;
    let width = -1;
    let height = -1;
    if (type === 'step') {
        const steps = extra.steps;
        const strokeWidth = extra.strokeWidth;
        if (typeof size === 'string' || typeof size === 'undefined') {
            width = size === 'small' ? 2 : 14;
            height = strokeWidth !== null && strokeWidth !== void 0 ? strokeWidth : 8;
        }
        else if (typeof size === 'number') {
            [width, height] = [size, size];
        }
        else {
            [width = 14, height = 8] = size;
        }
        width *= steps;
    }
    else if (type === 'line') {
        const strokeWidth = extra === null || extra === void 0 ? void 0 : extra.strokeWidth;
        if (typeof size === 'string' || typeof size === 'undefined') {
            height = strokeWidth || (size === 'small' ? 6 : 8);
        }
        else if (typeof size === 'number') {
            [width, height] = [size, size];
        }
        else {
            [width = -1, height = 8] = size;
        }
    }
    else if (type === 'circle' || type === 'dashboard') {
        if (typeof size === 'string' || typeof size === 'undefined') {
            [width, height] = size === 'small' ? [60, 60] : [120, 120];
        }
        else if (typeof size === 'number') {
            [width, height] = [size, size];
        }
        else {
            width = ((_b = (_a = size[0]) !== null && _a !== void 0 ? _a : size[1]) !== null && _b !== void 0 ? _b : 120);
            height = ((_d = (_c = size[0]) !== null && _c !== void 0 ? _c : size[1]) !== null && _d !== void 0 ? _d : 120);
        }
    }
    return [width, height];
};
