import { unit } from '@ant-design/cssinjs';
export default function genMaxMin(type) {
    if (type === 'js') {
        return {
            max: Math.max,
            min: Math.min,
        };
    }
    return {
        max: (...args) => `max(${args.map((value) => unit(value)).join(',')})`,
        min: (...args) => `min(${args.map((value) => unit(value)).join(',')})`,
    };
}
