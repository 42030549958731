var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import CheckCircleFilled from '@ant-design/icons/CheckCircleFilled';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import InfoCircleFilled from '@ant-design/icons/InfoCircleFilled';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import classNames from 'classnames';
import { Notice } from 'rc-notification';
import { ConfigContext } from '../config-provider';
import useCSSVarCls from '../config-provider/hooks/useCSSVarCls';
import useStyle from './style';
import PurePanelStyle from './style/pure-panel';
export const TypeIcon = {
    info: React.createElement(InfoCircleFilled, null),
    success: React.createElement(CheckCircleFilled, null),
    error: React.createElement(CloseCircleFilled, null),
    warning: React.createElement(ExclamationCircleFilled, null),
    loading: React.createElement(LoadingOutlined, null),
};
export function getCloseIcon(prefixCls, closeIcon) {
    if (closeIcon === null || closeIcon === false) {
        return null;
    }
    return (closeIcon || (React.createElement("span", { className: `${prefixCls}-close-x` },
        React.createElement(CloseOutlined, { className: `${prefixCls}-close-icon` }))));
}
const typeToIcon = {
    success: CheckCircleFilled,
    info: InfoCircleFilled,
    error: CloseCircleFilled,
    warning: ExclamationCircleFilled,
};
export const PureContent = (props) => {
    const { prefixCls, icon, type, message, description, btn, role = 'alert' } = props;
    let iconNode = null;
    if (icon) {
        iconNode = React.createElement("span", { className: `${prefixCls}-icon` }, icon);
    }
    else if (type) {
        iconNode = React.createElement(typeToIcon[type] || null, {
            className: classNames(`${prefixCls}-icon`, `${prefixCls}-icon-${type}`),
        });
    }
    return (React.createElement("div", { className: classNames({ [`${prefixCls}-with-icon`]: iconNode }), role: role },
        iconNode,
        React.createElement("div", { className: `${prefixCls}-message` }, message),
        React.createElement("div", { className: `${prefixCls}-description` }, description),
        btn && React.createElement("div", { className: `${prefixCls}-btn` }, btn)));
};
/** @private Internal Component. Do not use in your production. */
const PurePanel = (props) => {
    const { prefixCls: staticPrefixCls, className, icon, type, message, description, btn, closable = true, closeIcon, className: notificationClassName } = props, restProps = __rest(props, ["prefixCls", "className", "icon", "type", "message", "description", "btn", "closable", "closeIcon", "className"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = staticPrefixCls || getPrefixCls('notification');
    const noticePrefixCls = `${prefixCls}-notice`;
    const rootCls = useCSSVarCls(prefixCls);
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls, rootCls);
    return wrapCSSVar(React.createElement("div", { className: classNames(`${noticePrefixCls}-pure-panel`, hashId, className, cssVarCls, rootCls) },
        React.createElement(PurePanelStyle, { prefixCls: prefixCls }),
        React.createElement(Notice, Object.assign({}, restProps, { prefixCls: prefixCls, eventKey: "pure", duration: null, closable: closable, className: classNames({
                notificationClassName,
            }), closeIcon: getCloseIcon(prefixCls, closeIcon), content: React.createElement(PureContent, { prefixCls: noticePrefixCls, icon: icon, type: type, message: message, description: description, btn: btn }) }))));
};
export default PurePanel;
