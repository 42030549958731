import * as React from 'react';
import { SpaceContext } from './context';
const Item = ({ className, index, children, split, style }) => {
    const { latestIndex } = React.useContext(SpaceContext);
    if (children === null || children === undefined) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: className, style: style }, children),
        index < latestIndex && split && React.createElement("span", { className: `${className}-split` }, split)));
};
export default Item;
