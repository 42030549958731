// Proxy the dom ref with `{ nativeElement, otherFn }` type
// ref: https://github.com/ant-design/ant-design/discussions/45242
import { useImperativeHandle } from 'react';
function fillProxy(element, handler) {
    element._antProxy = element._antProxy || {};
    Object.keys(handler).forEach((key) => {
        if (!(key in element._antProxy)) {
            const ori = element[key];
            element._antProxy[key] = ori;
            element[key] = handler[key];
        }
    });
    return element;
}
export default function useProxyImperativeHandle(ref, init) {
    return useImperativeHandle(ref, () => {
        const refObj = init();
        const { nativeElement } = refObj;
        if (typeof Proxy !== 'undefined') {
            return new Proxy(nativeElement, {
                get(obj, prop) {
                    if (refObj[prop]) {
                        return refObj[prop];
                    }
                    return Reflect.get(obj, prop);
                },
            });
        }
        // Fallback of IE
        return fillProxy(nativeElement, refObj);
    });
}
