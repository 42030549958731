import * as React from 'react';
import classNames from 'classnames';
import { Field, FieldContext, ListContext } from 'rc-field-form';
import useState from 'rc-util/lib/hooks/useState';
import { supportRef } from 'rc-util/lib/ref';
import { cloneElement, isValidElement } from '../../_util/reactNode';
import { devUseWarning } from '../../_util/warning';
import { ConfigContext } from '../../config-provider';
import useCSSVarCls from '../../config-provider/hooks/useCSSVarCls';
import { FormContext, NoStyleItemContext } from '../context';
import useChildren from '../hooks/useChildren';
import useFormItemStatus from '../hooks/useFormItemStatus';
import useFrameState from '../hooks/useFrameState';
import useItemRef from '../hooks/useItemRef';
import useStyle from '../style';
import { getFieldId, toArray } from '../util';
import ItemHolder from './ItemHolder';
import StatusProvider from './StatusProvider';
const NAME_SPLIT = '__SPLIT__';
const ValidateStatuses = ['success', 'warning', 'error', 'validating', ''];
// https://github.com/ant-design/ant-design/issues/46417
// `getValueProps` may modify the value props name,
// we should check if the control is similar.
function isSimilarControl(a, b) {
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);
    return (keysA.length === keysB.length &&
        keysA.every((key) => {
            const propValueA = a[key];
            const propValueB = b[key];
            return (propValueA === propValueB ||
                typeof propValueA === 'function' ||
                typeof propValueB === 'function');
        }));
}
const MemoInput = React.memo(({ children }) => children, (prev, next) => isSimilarControl(prev.control, next.control) &&
    prev.update === next.update &&
    prev.childProps.length === next.childProps.length &&
    prev.childProps.every((value, index) => value === next.childProps[index]));
function genEmptyMeta() {
    return {
        errors: [],
        warnings: [],
        touched: false,
        validating: false,
        name: [],
        validated: false,
    };
}
function InternalFormItem(props) {
    const { name, noStyle, className, dependencies, prefixCls: customizePrefixCls, shouldUpdate, rules, children, required, label, messageVariables, trigger = 'onChange', validateTrigger, hidden, help, } = props;
    const { getPrefixCls } = React.useContext(ConfigContext);
    const { name: formName } = React.useContext(FormContext);
    const mergedChildren = useChildren(children);
    const isRenderProps = typeof mergedChildren === 'function';
    const notifyParentMetaChange = React.useContext(NoStyleItemContext);
    const { validateTrigger: contextValidateTrigger } = React.useContext(FieldContext);
    const mergedValidateTrigger = validateTrigger !== undefined ? validateTrigger : contextValidateTrigger;
    const hasName = !(name === undefined || name === null);
    const prefixCls = getPrefixCls('form', customizePrefixCls);
    // Style
    const rootCls = useCSSVarCls(prefixCls);
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls, rootCls);
    // ========================= Warn =========================
    const warning = devUseWarning('Form.Item');
    if (process.env.NODE_ENV !== 'production') {
        warning(name !== null, 'usage', '`null` is passed as `name` property');
    }
    // ========================= MISC =========================
    // Get `noStyle` required info
    const listContext = React.useContext(ListContext);
    const fieldKeyPathRef = React.useRef();
    // ======================== Errors ========================
    // >>>>> Collect sub field errors
    const [subFieldErrors, setSubFieldErrors] = useFrameState({});
    // >>>>> Current field errors
    const [meta, setMeta] = useState(() => genEmptyMeta());
    const onMetaChange = (nextMeta) => {
        // This keyInfo is not correct when field is removed
        // Since origin keyManager no longer keep the origin key anymore
        // Which means we need cache origin one and reuse when removed
        const keyInfo = listContext === null || listContext === void 0 ? void 0 : listContext.getKey(nextMeta.name);
        // Destroy will reset all the meta
        setMeta(nextMeta.destroy ? genEmptyMeta() : nextMeta, true);
        // Bump to parent since noStyle
        if (noStyle && help !== false && notifyParentMetaChange) {
            let namePath = nextMeta.name;
            if (!nextMeta.destroy) {
                if (keyInfo !== undefined) {
                    const [fieldKey, restPath] = keyInfo;
                    namePath = [fieldKey, ...restPath];
                    fieldKeyPathRef.current = namePath;
                }
            }
            else {
                // Use origin cache data
                namePath = fieldKeyPathRef.current || namePath;
            }
            notifyParentMetaChange(nextMeta, namePath);
        }
    };
    // >>>>> Collect noStyle Field error to the top FormItem
    const onSubItemMetaChange = (subMeta, uniqueKeys) => {
        // Only `noStyle` sub item will trigger
        setSubFieldErrors((prevSubFieldErrors) => {
            const clone = Object.assign({}, prevSubFieldErrors);
            // name: ['user', 1] + key: [4] = ['user', 4]
            const mergedNamePath = [...subMeta.name.slice(0, -1), ...uniqueKeys];
            const mergedNameKey = mergedNamePath.join(NAME_SPLIT);
            if (subMeta.destroy) {
                // Remove
                delete clone[mergedNameKey];
            }
            else {
                // Update
                clone[mergedNameKey] = subMeta;
            }
            return clone;
        });
    };
    // >>>>> Get merged errors
    const [mergedErrors, mergedWarnings] = React.useMemo(() => {
        const errorList = [...meta.errors];
        const warningList = [...meta.warnings];
        Object.values(subFieldErrors).forEach((subFieldError) => {
            errorList.push(...(subFieldError.errors || []));
            warningList.push(...(subFieldError.warnings || []));
        });
        return [errorList, warningList];
    }, [subFieldErrors, meta.errors, meta.warnings]);
    // ===================== Children Ref =====================
    const getItemRef = useItemRef();
    // ======================== Render ========================
    function renderLayout(baseChildren, fieldId, isRequired) {
        if (noStyle && !hidden) {
            return (React.createElement(StatusProvider, { prefixCls: prefixCls, hasFeedback: props.hasFeedback, validateStatus: props.validateStatus, meta: meta, errors: mergedErrors, warnings: mergedWarnings, noStyle: true }, baseChildren));
        }
        return (React.createElement(ItemHolder, Object.assign({ key: "row" }, props, { className: classNames(className, cssVarCls, rootCls, hashId), prefixCls: prefixCls, fieldId: fieldId, isRequired: isRequired, errors: mergedErrors, warnings: mergedWarnings, meta: meta, onSubItemMetaChange: onSubItemMetaChange }), baseChildren));
    }
    if (!hasName && !isRenderProps && !dependencies) {
        return wrapCSSVar(renderLayout(mergedChildren));
    }
    let variables = {};
    if (typeof label === 'string') {
        variables.label = label;
    }
    else if (name) {
        variables.label = String(name);
    }
    if (messageVariables) {
        variables = Object.assign(Object.assign({}, variables), messageVariables);
    }
    // >>>>> With Field
    return wrapCSSVar(React.createElement(Field, Object.assign({}, props, { messageVariables: variables, trigger: trigger, validateTrigger: mergedValidateTrigger, onMetaChange: onMetaChange }), (control, renderMeta, context) => {
        const mergedName = toArray(name).length && renderMeta ? renderMeta.name : [];
        const fieldId = getFieldId(mergedName, formName);
        const isRequired = required !== undefined
            ? required
            : !!(rules &&
                rules.some((rule) => {
                    if (rule && typeof rule === 'object' && rule.required && !rule.warningOnly) {
                        return true;
                    }
                    if (typeof rule === 'function') {
                        const ruleEntity = rule(context);
                        return ruleEntity && ruleEntity.required && !ruleEntity.warningOnly;
                    }
                    return false;
                }));
        // ======================= Children =======================
        const mergedControl = Object.assign({}, control);
        let childNode = null;
        warning(!(shouldUpdate && dependencies), 'usage', "`shouldUpdate` and `dependencies` shouldn't be used together. See https://u.ant.design/form-deps.");
        if (Array.isArray(mergedChildren) && hasName) {
            warning(false, 'usage', 'A `Form.Item` with a `name` prop must have a single child element. For information on how to render more complex form items, see https://u.ant.design/complex-form-item.');
            childNode = mergedChildren;
        }
        else if (isRenderProps && (!(shouldUpdate || dependencies) || hasName)) {
            warning(!!(shouldUpdate || dependencies), 'usage', 'A `Form.Item` with a render function must have either `shouldUpdate` or `dependencies`.');
            warning(!hasName, 'usage', 'A `Form.Item` with a render function cannot be a field, and thus cannot have a `name` prop.');
        }
        else if (dependencies && !isRenderProps && !hasName) {
            warning(false, 'usage', 'Must set `name` or use a render function when `dependencies` is set.');
        }
        else if (isValidElement(mergedChildren)) {
            warning(mergedChildren.props.defaultValue === undefined, 'usage', '`defaultValue` will not work on controlled Field. You should use `initialValues` of Form instead.');
            const childProps = Object.assign(Object.assign({}, mergedChildren.props), mergedControl);
            if (!childProps.id) {
                childProps.id = fieldId;
            }
            if (help || mergedErrors.length > 0 || mergedWarnings.length > 0 || props.extra) {
                const describedbyArr = [];
                if (help || mergedErrors.length > 0) {
                    describedbyArr.push(`${fieldId}_help`);
                }
                if (props.extra) {
                    describedbyArr.push(`${fieldId}_extra`);
                }
                childProps['aria-describedby'] = describedbyArr.join(' ');
            }
            if (mergedErrors.length > 0) {
                childProps['aria-invalid'] = 'true';
            }
            if (isRequired) {
                childProps['aria-required'] = 'true';
            }
            if (supportRef(mergedChildren)) {
                childProps.ref = getItemRef(mergedName, mergedChildren);
            }
            // We should keep user origin event handler
            const triggers = new Set([
                ...toArray(trigger),
                ...toArray(mergedValidateTrigger),
            ]);
            triggers.forEach((eventName) => {
                childProps[eventName] = (...args) => {
                    var _a, _b, _c;
                    (_a = mergedControl[eventName]) === null || _a === void 0 ? void 0 : _a.call(mergedControl, ...args);
                    (_c = (_b = mergedChildren.props)[eventName]) === null || _c === void 0 ? void 0 : _c.call(_b, ...args);
                };
            });
            // List of props that need to be watched for changes -> if changes are detected in MemoInput -> rerender
            const watchingChildProps = [
                childProps['aria-required'],
                childProps['aria-invalid'],
                childProps['aria-describedby'],
            ];
            childNode = (React.createElement(MemoInput, { control: mergedControl, update: mergedChildren, childProps: watchingChildProps }, cloneElement(mergedChildren, childProps)));
        }
        else if (isRenderProps && (shouldUpdate || dependencies) && !hasName) {
            childNode = mergedChildren(context);
        }
        else {
            warning(!mergedName.length, 'usage', '`name` is only used for validate React element. If you are using Form.Item as layout display, please remove `name` instead.');
            childNode = mergedChildren;
        }
        return renderLayout(childNode, fieldId, isRequired);
    }));
}
const FormItem = InternalFormItem;
FormItem.useStatus = useFormItemStatus;
export default FormItem;
