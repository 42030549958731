import React from 'react';
import SizeContext from '../SizeContext';
const useSize = (customSize) => {
    const size = React.useContext(SizeContext);
    const mergedSize = React.useMemo(() => {
        if (!customSize) {
            return size;
        }
        if (typeof customSize === 'string') {
            return customSize !== null && customSize !== void 0 ? customSize : size;
        }
        if (customSize instanceof Function) {
            return customSize(size);
        }
        return size;
    }, [customSize, size]);
    return mergedSize;
};
export default useSize;
