import { generate } from '@ant-design/colors';
import { getAlphaColor, getSolidColor } from './colorAlgorithm';
export const generateColorPalettes = (baseColor) => {
    const colors = generate(baseColor, { theme: 'dark' });
    return {
        1: colors[0],
        2: colors[1],
        3: colors[2],
        4: colors[3],
        5: colors[6],
        6: colors[5],
        7: colors[4],
        8: colors[6],
        9: colors[5],
        10: colors[4],
        // 8: colors[9],
        // 9: colors[8],
        // 10: colors[7],
    };
};
export const generateNeutralColorPalettes = (bgBaseColor, textBaseColor) => {
    const colorBgBase = bgBaseColor || '#000';
    const colorTextBase = textBaseColor || '#fff';
    return {
        colorBgBase,
        colorTextBase,
        colorText: getAlphaColor(colorTextBase, 0.85),
        colorTextSecondary: getAlphaColor(colorTextBase, 0.65),
        colorTextTertiary: getAlphaColor(colorTextBase, 0.45),
        colorTextQuaternary: getAlphaColor(colorTextBase, 0.25),
        colorFill: getAlphaColor(colorTextBase, 0.18),
        colorFillSecondary: getAlphaColor(colorTextBase, 0.12),
        colorFillTertiary: getAlphaColor(colorTextBase, 0.08),
        colorFillQuaternary: getAlphaColor(colorTextBase, 0.04),
        colorBgElevated: getSolidColor(colorBgBase, 12),
        colorBgContainer: getSolidColor(colorBgBase, 8),
        colorBgLayout: getSolidColor(colorBgBase, 0),
        colorBgSpotlight: getSolidColor(colorBgBase, 26),
        colorBgBlur: getAlphaColor(colorTextBase, 0.04),
        colorBorder: getSolidColor(colorBgBase, 26),
        colorBorderSecondary: getSolidColor(colorBgBase, 19),
    };
};
