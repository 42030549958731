import { resetComponent } from '../../style';
import { initZoomMotion } from '../../style/motion';
import getArrowStyle, { getArrowOffsetToken, MAX_VERTICAL_CONTENT_RADIUS, } from '../../style/placementArrow';
import { genPresetColor, genStyleHooks, mergeToken } from '../../theme/internal';
import { unit } from '@ant-design/cssinjs';
import { getArrowToken } from '../../style/roundedArrow';
const genTooltipStyle = (token) => {
    const { componentCls, // ant-tooltip
    tooltipMaxWidth, tooltipColor, tooltipBg, tooltipBorderRadius, zIndexPopup, controlHeight, boxShadowSecondary, paddingSM, paddingXS, } = token;
    return [
        {
            [componentCls]: Object.assign(Object.assign(Object.assign(Object.assign({}, resetComponent(token)), { position: 'absolute', zIndex: zIndexPopup, display: 'block', width: 'max-content', maxWidth: tooltipMaxWidth, visibility: 'visible', transformOrigin: `var(--arrow-x, 50%) var(--arrow-y, 50%)`, '&-hidden': {
                    display: 'none',
                }, '--antd-arrow-background-color': tooltipBg, 
                // Wrapper for the tooltip content
                [`${componentCls}-inner`]: {
                    minWidth: controlHeight,
                    minHeight: controlHeight,
                    padding: `${unit(token.calc(paddingSM).div(2).equal())} ${unit(paddingXS)}`,
                    color: tooltipColor,
                    textAlign: 'start',
                    textDecoration: 'none',
                    wordWrap: 'break-word',
                    backgroundColor: tooltipBg,
                    borderRadius: tooltipBorderRadius,
                    boxShadow: boxShadowSecondary,
                    boxSizing: 'border-box',
                }, 
                // Limit left and right placement radius
                [[
                    `&-placement-left`,
                    `&-placement-leftTop`,
                    `&-placement-leftBottom`,
                    `&-placement-right`,
                    `&-placement-rightTop`,
                    `&-placement-rightBottom`,
                ].join(',')]: {
                    [`${componentCls}-inner`]: {
                        borderRadius: token.min(tooltipBorderRadius, MAX_VERTICAL_CONTENT_RADIUS),
                    },
                }, [`${componentCls}-content`]: {
                    position: 'relative',
                } }), genPresetColor(token, (colorKey, { darkColor }) => ({
                [`&${componentCls}-${colorKey}`]: {
                    [`${componentCls}-inner`]: {
                        backgroundColor: darkColor,
                    },
                    [`${componentCls}-arrow`]: {
                        '--antd-arrow-background-color': darkColor,
                    },
                },
            }))), { 
                // RTL
                '&-rtl': {
                    direction: 'rtl',
                } }),
        },
        // Arrow Style
        getArrowStyle(token, 'var(--antd-arrow-background-color)'),
        // Pure Render
        {
            [`${componentCls}-pure`]: {
                position: 'relative',
                maxWidth: 'none',
                margin: token.sizePopupArrow,
            },
        },
    ];
};
// ============================== Export ==============================
export const prepareComponentToken = (token) => (Object.assign(Object.assign({ zIndexPopup: token.zIndexPopupBase + 70 }, getArrowOffsetToken({
    contentRadius: token.borderRadius,
    limitVerticalRadius: true,
})), getArrowToken(mergeToken(token, {
    borderRadiusOuter: Math.min(token.borderRadiusOuter, 4),
}))));
export default (prefixCls, injectStyle = true) => {
    const useStyle = genStyleHooks('Tooltip', (token) => {
        const { borderRadius, colorTextLightSolid, colorBgSpotlight } = token;
        const TooltipToken = mergeToken(token, {
            // default variables
            tooltipMaxWidth: 250,
            tooltipColor: colorTextLightSolid,
            tooltipBorderRadius: borderRadius,
            tooltipBg: colorBgSpotlight,
        });
        return [genTooltipStyle(TooltipToken), initZoomMotion(token, 'zoom-big-fast')];
    }, prepareComponentToken, {
        resetStyle: false,
        // Popover use Tooltip as internal component. We do not need to handle this.
        injectStyle,
    });
    return useStyle(prefixCls);
};
