export default function genSizeMapToken(token) {
    const { sizeUnit, sizeStep } = token;
    return {
        sizeXXL: sizeUnit * (sizeStep + 8), // 48
        sizeXL: sizeUnit * (sizeStep + 4), // 32
        sizeLG: sizeUnit * (sizeStep + 2), // 24
        sizeMD: sizeUnit * (sizeStep + 1), // 20
        sizeMS: sizeUnit * sizeStep, // 16
        size: sizeUnit * sizeStep, // 16
        sizeSM: sizeUnit * (sizeStep - 1), // 12
        sizeXS: sizeUnit * (sizeStep - 2), // 8
        sizeXXS: sizeUnit * (sizeStep - 3), // 4
    };
}
