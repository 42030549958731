import * as React from 'react';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import ConfigProvider, { ConfigContext } from '../config-provider';
export function withPureRenderTheme(Component) {
    return (props) => (React.createElement(ConfigProvider, { theme: { token: { motion: false, zIndexPopupBase: 0 } } },
        React.createElement(Component, Object.assign({}, props))));
}
/* istanbul ignore next */
const genPurePanel = (Component, defaultPrefixCls, getDropdownCls, postProps) => {
    const PurePanel = (props) => {
        const { prefixCls: customizePrefixCls, style } = props;
        const holderRef = React.useRef(null);
        const [popupHeight, setPopupHeight] = React.useState(0);
        const [popupWidth, setPopupWidth] = React.useState(0);
        const [open, setOpen] = useMergedState(false, {
            value: props.open,
        });
        const { getPrefixCls } = React.useContext(ConfigContext);
        const prefixCls = getPrefixCls(defaultPrefixCls || 'select', customizePrefixCls);
        React.useEffect(() => {
            // We do not care about ssr
            setOpen(true);
            if (typeof ResizeObserver !== 'undefined') {
                const resizeObserver = new ResizeObserver((entries) => {
                    const element = entries[0].target;
                    setPopupHeight(element.offsetHeight + 8);
                    setPopupWidth(element.offsetWidth);
                });
                const interval = setInterval(() => {
                    var _a;
                    const dropdownCls = getDropdownCls
                        ? `.${getDropdownCls(prefixCls)}`
                        : `.${prefixCls}-dropdown`;
                    const popup = (_a = holderRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(dropdownCls);
                    if (popup) {
                        clearInterval(interval);
                        resizeObserver.observe(popup);
                    }
                }, 10);
                return () => {
                    clearInterval(interval);
                    resizeObserver.disconnect();
                };
            }
        }, []);
        let mergedProps = Object.assign(Object.assign({}, props), { style: Object.assign(Object.assign({}, style), { margin: 0 }), open, visible: open, getPopupContainer: () => holderRef.current });
        if (postProps) {
            mergedProps = postProps(mergedProps);
        }
        const mergedStyle = {
            paddingBottom: popupHeight,
            position: 'relative',
            minWidth: popupWidth,
        };
        return (React.createElement("div", { ref: holderRef, style: mergedStyle },
            React.createElement(Component, Object.assign({}, mergedProps))));
    };
    return withPureRenderTheme(PurePanel);
};
export default genPurePanel;
