import React from 'react';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { DisabledContextProvider } from '../config-provider/DisabledContext';
import { useLocale } from '../locale';
import NormalCancelBtn from './components/NormalCancelBtn';
import NormalOkBtn from './components/NormalOkBtn';
import { ModalContextProvider } from './context';
import { getConfirmLocale } from './locale';
export function renderCloseIcon(prefixCls, closeIcon) {
    return (React.createElement("span", { className: `${prefixCls}-close-x` }, closeIcon || React.createElement(CloseOutlined, { className: `${prefixCls}-close-icon` })));
}
export const Footer = (props) => {
    const { okText, okType = 'primary', cancelText, confirmLoading, onOk, onCancel, okButtonProps, cancelButtonProps, footer, } = props;
    const [locale] = useLocale('Modal', getConfirmLocale());
    // ================== Locale Text ==================
    const okTextLocale = okText || (locale === null || locale === void 0 ? void 0 : locale.okText);
    const cancelTextLocale = cancelText || (locale === null || locale === void 0 ? void 0 : locale.cancelText);
    // ================= Context Value =================
    const btnCtxValue = {
        confirmLoading,
        okButtonProps,
        cancelButtonProps,
        okTextLocale,
        cancelTextLocale,
        okType,
        onOk,
        onCancel,
    };
    const btnCtxValueMemo = React.useMemo(() => btnCtxValue, [...Object.values(btnCtxValue)]);
    let footerNode;
    if (typeof footer === 'function' || typeof footer === 'undefined') {
        footerNode = (React.createElement(React.Fragment, null,
            React.createElement(NormalCancelBtn, null),
            React.createElement(NormalOkBtn, null)));
        if (typeof footer === 'function') {
            footerNode = footer(footerNode, {
                OkBtn: NormalOkBtn,
                CancelBtn: NormalCancelBtn,
            });
        }
        footerNode = React.createElement(ModalContextProvider, { value: btnCtxValueMemo }, footerNode);
    }
    else {
        footerNode = footer;
    }
    return React.createElement(DisabledContextProvider, { disabled: false }, footerNode);
};
