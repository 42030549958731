var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import RotateLeftOutlined from '@ant-design/icons/RotateLeftOutlined';
import RotateRightOutlined from '@ant-design/icons/RotateRightOutlined';
import SwapOutlined from '@ant-design/icons/SwapOutlined';
import ZoomInOutlined from '@ant-design/icons/ZoomInOutlined';
import ZoomOutOutlined from '@ant-design/icons/ZoomOutOutlined';
import classNames from 'classnames';
import RcImage from 'rc-image';
import { useZIndex } from '../_util/hooks/useZIndex';
import { getTransitionName } from '../_util/motion';
import { ConfigContext } from '../config-provider';
import useCSSVarCls from '../config-provider/hooks/useCSSVarCls';
import useStyle from './style';
export const icons = {
    rotateLeft: React.createElement(RotateLeftOutlined, null),
    rotateRight: React.createElement(RotateRightOutlined, null),
    zoomIn: React.createElement(ZoomInOutlined, null),
    zoomOut: React.createElement(ZoomOutOutlined, null),
    close: React.createElement(CloseOutlined, null),
    left: React.createElement(LeftOutlined, null),
    right: React.createElement(RightOutlined, null),
    flipX: React.createElement(SwapOutlined, null),
    flipY: React.createElement(SwapOutlined, { rotate: 90 }),
};
const InternalPreviewGroup = (_a) => {
    var { previewPrefixCls: customizePrefixCls, preview } = _a, props = __rest(_a, ["previewPrefixCls", "preview"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('image', customizePrefixCls);
    const previewPrefixCls = `${prefixCls}-preview`;
    const rootPrefixCls = getPrefixCls();
    const rootCls = useCSSVarCls(prefixCls);
    const [wrapCSSVar, hashId, cssVarCls] = useStyle(prefixCls, rootCls);
    const [zIndex] = useZIndex('ImagePreview', typeof preview === 'object' ? preview.zIndex : undefined);
    const mergedPreview = React.useMemo(() => {
        var _a;
        if (preview === false) {
            return preview;
        }
        const _preview = typeof preview === 'object' ? preview : {};
        const mergedRootClassName = classNames(hashId, cssVarCls, rootCls, (_a = _preview.rootClassName) !== null && _a !== void 0 ? _a : '');
        return Object.assign(Object.assign({}, _preview), { transitionName: getTransitionName(rootPrefixCls, 'zoom', _preview.transitionName), maskTransitionName: getTransitionName(rootPrefixCls, 'fade', _preview.maskTransitionName), rootClassName: mergedRootClassName, zIndex });
    }, [preview]);
    return wrapCSSVar(React.createElement(RcImage.PreviewGroup, Object.assign({ preview: mergedPreview, previewPrefixCls: previewPrefixCls, icons: icons }, props)));
};
export default InternalPreviewGroup;
