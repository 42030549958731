import React, { useEffect, useState } from 'react';
import { generateColor } from '../util';
import ColorSteppers from './ColorSteppers';
const ColorRgbInput = ({ prefixCls, value, onChange }) => {
    const colorRgbInputPrefixCls = `${prefixCls}-rgb-input`;
    const [rgbValue, setRgbValue] = useState(generateColor(value || '#000'));
    // Update step value
    useEffect(() => {
        if (value) {
            setRgbValue(value);
        }
    }, [value]);
    const handleRgbChange = (step, type) => {
        const rgb = rgbValue.toRgb();
        rgb[type] = step || 0;
        const genColor = generateColor(rgb);
        if (!value) {
            setRgbValue(genColor);
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(genColor);
    };
    return (React.createElement("div", { className: colorRgbInputPrefixCls },
        React.createElement(ColorSteppers, { max: 255, min: 0, value: Number(rgbValue.toRgb().r), prefixCls: prefixCls, className: colorRgbInputPrefixCls, onChange: (step) => handleRgbChange(Number(step), 'r') }),
        React.createElement(ColorSteppers, { max: 255, min: 0, value: Number(rgbValue.toRgb().g), prefixCls: prefixCls, className: colorRgbInputPrefixCls, onChange: (step) => handleRgbChange(Number(step), 'g') }),
        React.createElement(ColorSteppers, { max: 255, min: 0, value: Number(rgbValue.toRgb().b), prefixCls: prefixCls, className: colorRgbInputPrefixCls, onChange: (step) => handleRgbChange(Number(step), 'b') })));
};
export default ColorRgbInput;
