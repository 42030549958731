import React, { useContext } from 'react';
import ActionButton from '../../_util/ActionButton';
import { ModalContext } from '../context';
const ConfirmCancelBtn = () => {
    const { autoFocusButton, cancelButtonProps, cancelTextLocale, isSilent, mergedOkCancel, rootPrefixCls, close, onCancel, onConfirm, } = useContext(ModalContext);
    return mergedOkCancel ? (React.createElement(ActionButton, { isSilent: isSilent, actionFn: onCancel, close: (...args) => {
            close === null || close === void 0 ? void 0 : close(...args);
            onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm(false);
        }, autoFocus: autoFocusButton === 'cancel', buttonProps: cancelButtonProps, prefixCls: `${rootPrefixCls}-btn` }, cancelTextLocale)) : null;
};
export default ConfirmCancelBtn;
