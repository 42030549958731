import * as React from 'react';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
export default function useColumnIcons(prefixCls, rtl, expandIcon) {
    let mergedExpandIcon = expandIcon;
    if (!expandIcon) {
        mergedExpandIcon = rtl ? React.createElement(LeftOutlined, null) : React.createElement(RightOutlined, null);
    }
    const loadingIcon = (React.createElement("span", { className: `${prefixCls}-menu-item-loading-icon` },
        React.createElement(LoadingOutlined, { spin: true })));
    return [mergedExpandIcon, loadingIcon];
}
