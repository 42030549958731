var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useState } from 'react';
import extendsObject from '../../_util/extendsObject';
export const DEFAULT_PAGE_SIZE = 10;
export function getPaginationParam(mergedPagination, pagination) {
    const param = {
        current: mergedPagination.current,
        pageSize: mergedPagination.pageSize,
    };
    const paginationObj = pagination && typeof pagination === 'object' ? pagination : {};
    Object.keys(paginationObj).forEach((pageProp) => {
        const value = mergedPagination[pageProp];
        if (typeof value !== 'function') {
            param[pageProp] = value;
        }
    });
    return param;
}
function usePagination(total, onChange, pagination) {
    const _a = pagination && typeof pagination === 'object' ? pagination : {}, { total: paginationTotal = 0 } = _a, paginationObj = __rest(_a, ["total"]);
    const [innerPagination, setInnerPagination] = useState(() => ({
        current: 'defaultCurrent' in paginationObj ? paginationObj.defaultCurrent : 1,
        pageSize: 'defaultPageSize' in paginationObj ? paginationObj.defaultPageSize : DEFAULT_PAGE_SIZE,
    }));
    // ============ Basic Pagination Config ============
    const mergedPagination = extendsObject(innerPagination, paginationObj, {
        total: paginationTotal > 0 ? paginationTotal : total,
    });
    // Reset `current` if data length or pageSize changed
    const maxPage = Math.ceil((paginationTotal || total) / mergedPagination.pageSize);
    if (mergedPagination.current > maxPage) {
        // Prevent a maximum page count of 0
        mergedPagination.current = maxPage || 1;
    }
    const refreshPagination = (current, pageSize) => {
        setInnerPagination({
            current: current !== null && current !== void 0 ? current : 1,
            pageSize: pageSize || mergedPagination.pageSize,
        });
    };
    const onInternalChange = (current, pageSize) => {
        var _a;
        if (pagination) {
            (_a = pagination.onChange) === null || _a === void 0 ? void 0 : _a.call(pagination, current, pageSize);
        }
        refreshPagination(current, pageSize);
        onChange(current, pageSize || (mergedPagination === null || mergedPagination === void 0 ? void 0 : mergedPagination.pageSize));
    };
    if (pagination === false) {
        return [{}, () => { }];
    }
    return [
        Object.assign(Object.assign({}, mergedPagination), { onChange: onInternalChange }),
        refreshPagination,
    ];
}
export default usePagination;
