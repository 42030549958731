import React, { memo } from 'react';
import FileTextOutlined from '@ant-design/icons/FileTextOutlined';
import classNames from 'classnames';
const FloatButtonContent = (props) => {
    const { icon, description, prefixCls, className } = props;
    const defaultElement = (React.createElement("div", { className: `${prefixCls}-icon` },
        React.createElement(FileTextOutlined, null)));
    return (React.createElement("div", { onClick: props.onClick, onFocus: props.onFocus, onMouseEnter: props.onMouseEnter, onMouseLeave: props.onMouseLeave, className: classNames(className, `${prefixCls}-content`) }, icon || description ? (React.createElement(React.Fragment, null,
        icon && React.createElement("div", { className: `${prefixCls}-icon` }, icon),
        description && React.createElement("div", { className: `${prefixCls}-description` }, description))) : (defaultElement)));
};
export default memo(FloatButtonContent);
