const initMotionCommon = (duration) => ({
    animationDuration: duration,
    animationFillMode: 'both',
});
// FIXME: origin less code seems same as initMotionCommon. Maybe we can safe remove
const initMotionCommonLeave = (duration) => ({
    animationDuration: duration,
    animationFillMode: 'both',
});
export const initMotion = (motionCls, inKeyframes, outKeyframes, duration, sameLevel = false) => {
    const sameLevelPrefix = sameLevel ? '&' : '';
    return {
        [`
      ${sameLevelPrefix}${motionCls}-enter,
      ${sameLevelPrefix}${motionCls}-appear
    `]: Object.assign(Object.assign({}, initMotionCommon(duration)), { animationPlayState: 'paused' }),
        [`${sameLevelPrefix}${motionCls}-leave`]: Object.assign(Object.assign({}, initMotionCommonLeave(duration)), { animationPlayState: 'paused' }),
        [`
      ${sameLevelPrefix}${motionCls}-enter${motionCls}-enter-active,
      ${sameLevelPrefix}${motionCls}-appear${motionCls}-appear-active
    `]: {
            animationName: inKeyframes,
            animationPlayState: 'running',
        },
        [`${sameLevelPrefix}${motionCls}-leave${motionCls}-leave-active`]: {
            animationName: outKeyframes,
            animationPlayState: 'running',
            pointerEvents: 'none',
        },
    };
};
