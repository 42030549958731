var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import ExclamationCircleFilled from '@ant-design/icons/ExclamationCircleFilled';
import classNames from 'classnames';
import * as React from 'react';
import ActionButton from '../_util/ActionButton';
import { getRenderPropValue } from '../_util/getRenderPropValue';
import Button from '../button';
import { convertLegacyProps } from '../button/buttonHelpers';
import { ConfigContext } from '../config-provider';
import { useLocale } from '../locale';
import defaultLocale from '../locale/en_US';
import PopoverPurePanel from '../popover/PurePanel';
import useStyle from './style';
export const Overlay = (props) => {
    const { prefixCls, okButtonProps, cancelButtonProps, title, description, cancelText, okText, okType = 'primary', icon = React.createElement(ExclamationCircleFilled, null), showCancel = true, close, onConfirm, onCancel, onPopupClick, } = props;
    const { getPrefixCls } = React.useContext(ConfigContext);
    const [contextLocale] = useLocale('Popconfirm', defaultLocale.Popconfirm);
    const theTitle = getRenderPropValue(title);
    const theDescription = getRenderPropValue(description);
    return (React.createElement("div", { className: `${prefixCls}-inner-content`, onClick: onPopupClick },
        React.createElement("div", { className: `${prefixCls}-message` },
            icon && React.createElement("span", { className: `${prefixCls}-message-icon` }, icon),
            React.createElement("div", { className: `${prefixCls}-message-text` },
                theTitle && React.createElement("div", { className: classNames(`${prefixCls}-title`) }, theTitle),
                theDescription && React.createElement("div", { className: `${prefixCls}-description` }, theDescription))),
        React.createElement("div", { className: `${prefixCls}-buttons` },
            showCancel && (React.createElement(Button, Object.assign({ onClick: onCancel, size: "small" }, cancelButtonProps), cancelText !== null && cancelText !== void 0 ? cancelText : contextLocale === null || contextLocale === void 0 ? void 0 : contextLocale.cancelText)),
            React.createElement(ActionButton, { buttonProps: Object.assign(Object.assign({ size: 'small' }, convertLegacyProps(okType)), okButtonProps), actionFn: onConfirm, close: close, prefixCls: getPrefixCls('btn'), quitOnNullishReturnValue: true, emitEvent: true }, okText !== null && okText !== void 0 ? okText : contextLocale === null || contextLocale === void 0 ? void 0 : contextLocale.okText))));
};
const PurePanel = (props) => {
    const { prefixCls: customizePrefixCls, placement, className, style } = props, restProps = __rest(props, ["prefixCls", "placement", "className", "style"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('popconfirm', customizePrefixCls);
    const [wrapCSSVar] = useStyle(prefixCls);
    return wrapCSSVar(React.createElement(PopoverPurePanel, { placement: placement, className: classNames(prefixCls, className), style: style, content: React.createElement(Overlay, Object.assign({ prefixCls: prefixCls }, restProps)) }));
};
export default PurePanel;
