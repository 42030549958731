import { unit } from '@ant-design/cssinjs';
const genSelectionStyle = (token) => {
    const { componentCls, antCls, iconCls, fontSizeIcon, padding, paddingXS, headerIconColor, headerIconHoverColor, tableSelectionColumnWidth, tableSelectedRowBg, tableSelectedRowHoverBg, tableRowHoverBg, tablePaddingHorizontal, calc, } = token;
    return {
        [`${componentCls}-wrapper`]: {
            // ========================== Selections ==========================
            [`${componentCls}-selection-col`]: {
                width: tableSelectionColumnWidth,
                [`&${componentCls}-selection-col-with-dropdown`]: {
                    width: calc(tableSelectionColumnWidth)
                        .add(fontSizeIcon)
                        .add(calc(padding).div(4))
                        .equal(),
                },
            },
            [`${componentCls}-bordered ${componentCls}-selection-col`]: {
                width: calc(tableSelectionColumnWidth).add(calc(paddingXS).mul(2)).equal(),
                [`&${componentCls}-selection-col-with-dropdown`]: {
                    width: calc(tableSelectionColumnWidth)
                        .add(fontSizeIcon)
                        .add(calc(padding).div(4))
                        .add(calc(paddingXS).mul(2))
                        .equal(),
                },
            },
            [`
        table tr th${componentCls}-selection-column,
        table tr td${componentCls}-selection-column,
        ${componentCls}-selection-column
      `]: {
                paddingInlineEnd: token.paddingXS,
                paddingInlineStart: token.paddingXS,
                textAlign: 'center',
                [`${antCls}-radio-wrapper`]: {
                    marginInlineEnd: 0,
                },
            },
            [`table tr th${componentCls}-selection-column${componentCls}-cell-fix-left`]: {
                zIndex: token.zIndexTableFixed + 1,
            },
            [`table tr th${componentCls}-selection-column::after`]: {
                backgroundColor: 'transparent !important',
            },
            [`${componentCls}-selection`]: {
                position: 'relative',
                display: 'inline-flex',
                flexDirection: 'column',
            },
            [`${componentCls}-selection-extra`]: {
                position: 'absolute',
                top: 0,
                zIndex: 1,
                cursor: 'pointer',
                transition: `all ${token.motionDurationSlow}`,
                marginInlineStart: '100%',
                paddingInlineStart: unit(calc(tablePaddingHorizontal).div(4).equal()),
                [iconCls]: {
                    color: headerIconColor,
                    fontSize: fontSizeIcon,
                    verticalAlign: 'baseline',
                    '&:hover': {
                        color: headerIconHoverColor,
                    },
                },
            },
            // ============================= Rows =============================
            [`${componentCls}-tbody`]: {
                [`${componentCls}-row`]: {
                    [`&${componentCls}-row-selected`]: {
                        [`> ${componentCls}-cell`]: {
                            background: tableSelectedRowBg,
                            '&-row-hover': {
                                background: tableSelectedRowHoverBg,
                            },
                        },
                    },
                    [`> ${componentCls}-cell-row-hover`]: {
                        background: tableRowHoverBg,
                    },
                },
            },
        },
    };
};
export default genSelectionStyle;
