import * as React from 'react';
import CheckOutlined from '@ant-design/icons/CheckOutlined';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import DownOutlined from '@ant-design/icons/DownOutlined';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import { devUseWarning } from '../_util/warning';
export default function useIcons({ suffixIcon, clearIcon, menuItemSelectedIcon, removeIcon, loading, multiple, hasFeedback, prefixCls, showSuffixIcon, feedbackIcon, showArrow, componentName, }) {
    if (process.env.NODE_ENV !== 'production') {
        const warning = devUseWarning(componentName);
        warning.deprecated(!clearIcon, 'clearIcon', 'allowClear={{ clearIcon: React.ReactNode }}');
    }
    // Clear Icon
    const mergedClearIcon = clearIcon !== null && clearIcon !== void 0 ? clearIcon : React.createElement(CloseCircleFilled, null);
    // Validation Feedback Icon
    const getSuffixIconNode = (arrowIcon) => {
        if (suffixIcon === null && !hasFeedback && !showArrow) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            showSuffixIcon !== false && arrowIcon,
            hasFeedback && feedbackIcon));
    };
    // Arrow item icon
    let mergedSuffixIcon = null;
    if (suffixIcon !== undefined) {
        mergedSuffixIcon = getSuffixIconNode(suffixIcon);
    }
    else if (loading) {
        mergedSuffixIcon = getSuffixIconNode(React.createElement(LoadingOutlined, { spin: true }));
    }
    else {
        const iconCls = `${prefixCls}-suffix`;
        mergedSuffixIcon = ({ open, showSearch }) => {
            if (open && showSearch) {
                return getSuffixIconNode(React.createElement(SearchOutlined, { className: iconCls }));
            }
            return getSuffixIconNode(React.createElement(DownOutlined, { className: iconCls }));
        };
    }
    // Checked item icon
    let mergedItemIcon = null;
    if (menuItemSelectedIcon !== undefined) {
        mergedItemIcon = menuItemSelectedIcon;
    }
    else if (multiple) {
        mergedItemIcon = React.createElement(CheckOutlined, null);
    }
    else {
        mergedItemIcon = null;
    }
    let mergedRemoveIcon = null;
    if (removeIcon !== undefined) {
        mergedRemoveIcon = removeIcon;
    }
    else {
        mergedRemoveIcon = React.createElement(CloseOutlined, null);
    }
    return {
        clearIcon: mergedClearIcon,
        suffixIcon: mergedSuffixIcon,
        itemIcon: mergedItemIcon,
        removeIcon: mergedRemoveIcon,
    };
}
